import Header from "./components/Header/Header";
import Footer from "./components/Footers/Footer";
import './App.css';
import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";
import Registration from "./components/Registration/Registration";
import HomePage from "./components/HomePage/HomePage";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsOfServices from "./components/TermsOfServices/TermsOfServices";
import Payment from "./components/Payment/Payment";
import ThankyouPage from "./components/ThankyouPage/ThankyouPage";
const App = (props) => {

	return (
		<Router>
			<Header/>
			<div>
				<Switch>
					<Route path="/registration"><Registration state={props.state}/></Route>
					<Route path="/terms-of-service"><TermsOfServices /></Route>
					<Route path="/payment"><Payment state={props.state}/></Route>
					<Route path="/privacy-policy"><PrivacyPolicy state={props.state}/></Route>
					<Route path="/thanks"><ThankyouPage /></Route>
					<Route path="/thankyoupage"><ThankyouPage /></Route>
					<Route path="/thankyoupage_plus"><ThankyouPage /></Route>
					<Route path="/"><HomePage /></Route>
				</Switch>
			</div>
			<Footer/>
		</Router>
	)
}

export default App;
